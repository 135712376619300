/**
 * 搜索详情
 */
import Vue from 'vue'
import { Page, Input, DatePicker, locale, Table, Button, Message } from "iview";
import lang from 'iview/dist/locale/en-US';
locale(lang)
Vue.component('Page', Page);
Vue.component('Input', Input);
Vue.component('DatePicker', DatePicker);
Vue.component('Table', Table);
Vue.component('Button', Button);
import { getSearchInfoService,editSearchImageTextService } from '@/service/search-service'
import {
    language, terminal, content, sizeList, sizeSubList, leadingList, locationList, colorList, addProductDataService
} from "@/service/marketing-service";
import { classification } from '@/service/spu-group-service'
import { dateFormat, formatUnit } from "@/utils/tools";
import Header from '@/components/Header/header.vue'
import { mapMutations, mapState } from "vuex";
import StyleDown from "@/components/StyleDown/styleDown.vue";
const SearchEdit = {
    name:'SearchEdit',
    components:{
        Header,
        StyleDown
    },
    computed: {
        ...mapState({
            launchId: state => state.launch.launchId,  //获取状态管理投放id
            launchTitle: state => state.launch.launchTitle,  //获取状态管理投放标题
            launchName: state => state.launch.launchName,  //获取状态管理投放名称
            typeId: state => state.launch.typeId,  //获取状态管理分类id
            launchTab: state => state.launch.launchTab,  //获取状态管理分类tab
            selectList: state => state.product.selectList, //获取状态管理选择的商品
        }),
    },
    data() {
        return {
            loadingStatus:false,
            formatUnit:formatUnit,
            language:language,//语言
            terminal:terminal, //终端
            content:content, //内容
            sizeList:sizeList, //文字大小
            sizeSubList:sizeSubList, //副标题文字大小
            leadingList:leadingList, //间距大小
            locationList:locationList, //位置
            colorList:colorList, //颜色
            classification:classification,//分类
            editObj:{}, //编辑数据集
            searchVal:'', //搜索数据
            brandVisible:false, //品牌可显示
            searchList:[], //存放搜索的总数据
        }
    },
    created() {
        this.onSearchInfo().then()
    },
    methods: {
        ...mapMutations('product',['SET_SELECT_LIST']),
        /**
         * 选择商品
         */
        onSelectProduct() {
            this.$router.push('/product/select')
            this.SET_SELECT_LIST(this.editObj.en.selectionEn.products)
        },
        /**
         * 返回至列表页
         */
        onBack() {
            this.$router.back()
        },
        /**
         * 搜素
         * @param obj
         * @param key
         */
        onSearch(){
            this.editObj.en.brandList = this.onFilter()
        },
        /**
         * 选择品牌
         * @param brand
         */
        onSelectType(brand) {
            this.editObj.en.coverTitle = brand.name
            this.editObj.en.description = brand.id
        },
        /**
         * 过滤列表相同名称
         * @param content
         * @returns {*[]}
         */
        onFilter(){
            return this.searchList.filter((value)=>{  //过滤数组元素
                return value.name.includes(this.searchVal); //如果包含字符返回true
            });
        },
        /**
         * 选择起始月日
         * @param data
         */
        onStartMonthValue(data){
            this.editObj.en.startTime = data
        },
        /**
         * 选择结束月日
         * @param data
         */
        onEndMonthValue(data){
            this.editObj.en.endTime = data
        },
        /**
         * 选择终端
         * @param id
         */
        onTerminal(id){
            this.editObj.en.osType = id
        },
        /**
         * 提交数据
         */
        onEditSubmit(){
            this.onEditMarketImageText().then()
        },
        /**
         * 上传图片文件
         * @param event
         * @param n
         */
        onFileDataChange(event){
            const file =  event.target.files[0];
            if (file.size > 5 * 1024 * 1024) {
                Message.error('请选择5M以内的图片')
                return
            }
            this.onAddProductData(file).then()
        },
        /**
         * 获取Search 详情 数据
         * @returns {Promise<void>}
         */
        async onSearchInfo() {
            try {
                let params = {
                    id:this.launchId
                }
                const {code,data} = await getSearchInfoService(params)
                if(code === 1){
                    this.editObj = data
                    this.searchList = data.en.brandList
                    if(this.editObj.en.cid1 === '' || this.editObj.en.cid1 === null){
                        this.editObj.en.cid1 = this.typeId
                    }
                    if(this.editObj.en.type === '' || this.editObj.en.type === null){
                        this.editObj.en.type = this.launchTab
                    }
                    if(this.editObj.en.startTime === ''){
                        this.editObj.en.startTime = dateFormat("YYYY-mm-dd HH:MM:SS", new Date())
                    }
                    if(this.editObj.en.endTime === ''){
                        this.editObj.en.endTime = dateFormat("YYYY-mm-dd HH:MM:SS", new Date())
                    }
                }
            }catch (error){
                console.log(error)
            }
        },

        /**
         * 编辑图文数据
         * @returns {Promise<void>}
         */
        async onEditMarketImageText() {
            try {
                this.loadingStatus = true
                let params = this.editObj
                    params.en.selectionEn.products = this.selectList

                const { code } = await editSearchImageTextService(params)
                if(code === 1){
                    this.$router.push('/search/list')
                    this.SET_SELECT_LIST([])
                }
                this.loadingStatus = false
            }catch (error){
                this.loadingStatus = false
                console.log(error)
            }
        },
        /**
         * 上传图片文件
         */
        async onAddProductData(files) {
            try {
                let params =  new FormData()
                params.append('file',files)

                const { code,data } = await addProductDataService(params)
                if(code === 1){
                    Message.success('Success！')
                    this.editObj.en.coverImage = data.path
                }else{
                    Message.error('请求异常，请联系管理员！')
                }

            }catch (error){
                console.log(error)
            }
        },
    },
}
export default SearchEdit
