<template>
    <div class="layout-body" v-if="editObj.en">
        <!--s: Header-->
        <Header/>
        <!--e: Header-->

        <!--s: Type-->
        <div class="product-type">
            <div class="type-back"><a href="javascript:" @click="onBack">Back</a></div>
        </div>
        <!--e: Type-->

        <!--s: Launch -->
        <div class="search-body">

            <div class="edit-top">
                <span class="name">{{ launchName }} {{ launchId === ''?'- New':'' }}</span>
            </div>

            <div class="edit-check edit-border-bottom">
                <div class="box">
                    <ul>
                        <li :key="key" v-for="(lang,key) in language">
                            <i class="label-icon" :class="lang.checkBox?'select':'selected'"></i>
                            <span class="label-name" :class="lang.checkBox?'title':''">{{ lang.name }}</span>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="edit-check edit-data">
                <ul>
                    <li>
                        <div class="title">
                            <span class="name">Start</span>
                            <i class="type-icon"></i>
                            <DatePicker :value="editObj.en.startTime" placement="bottom-start" type="date" @on-change="onStartMonthValue" format="yyyy-MM-dd"></DatePicker>
                        </div>
                        <div class="key">{{ formatUnit(editObj.en.startTime) }}</div>
                    </li>
                    <li>
                        <div class="title">
                            <span class="name">End</span>
                            <i class="type-icon"></i>
                            <DatePicker :value="editObj.en.endTime" placement="bottom-start" type="date" @on-change="onEndMonthValue" format="yyyy-MM-dd"></DatePicker>
                        </div>
                        <div class="key">{{ formatUnit(editObj.en.endTime) }}</div>
                    </li>
                </ul>
            </div>

            <!--s: cover-->
            <div class="launch-box">
                <h1 class="headline">Cover & Content</h1>
                <div class="launch-terminal">
                    <a href="javascript:" :class="editObj.en.osType === item.id?'active':''" :key="key" v-for="(item,key) in terminal" @click="onTerminal(item.id)">{{ item.name }}</a>
                </div>

                <div class="launch-plug" v-if="launchTab!=4">
                    <StyleDown v-model="editObj.en.ctSize" disabled  title="Size" :width="70" :margin-right="25" :data="sizeSubList" />
                    <StyleDown v-model="editObj.en.ctLeading" disabled  title="Leading" :width="70" :margin-right="25" :data="leadingList"/>
                    <StyleDown v-model="editObj.en.ctLocation" disabled  title="Location" :width="70" :margin-right="25" :data="locationList"/>
                    <StyleDown v-model="editObj.en.ctColour"  title="Colour" color :width="70" :margin-right="25" :data="colorList"/>
                    <div class="launch-upload" v-if="launchTab == 2">
                        <span class="title">Cover image - 1270 x 480</span>
                        <div class="upload">
                            <span class="value">{{ editObj.en.coverImage }}</span>
                            <Button>Upload<input type="file" class="input" @change="onFileDataChange"/></Button>
                        </div>
                    </div>
                </div>
                <div class="launch-text" v-if="launchTab!=4">
                    <div class="box" style="margin-right: 30px">
                        <div class="title" v-if="launchTab == 1 || launchTab == 2">
                            <span style="float:left;">Cover title</span>
                            <span style="float:right;">Montserrat - ExtraBold</span>
                        </div>
                        <div class="title" v-if="launchTab == 3">
                            <span style="float:left;">Keyword</span>
                            <span style="float:right;">Montserrat - ExtraBold</span>
                        </div>
                        <div class="title" v-if="launchTab == 5">
                            <span style="float:left;">Featured</span>
                            <span style="float:right;">Montserrat - ExtraBold</span>
                        </div>
                        <div class="text">
                            <Input v-model="editObj.en.coverTitle" type="textarea" :rows="2"></Input>
                        </div>
                    </div>
                    <div class="box">
                        <div class="title" v-if="launchTab == 1 || launchTab == 2">
                            <span style="float:left;">封面标题</span>
                            <span style="float:right;">方正兰亭 - 特黑 / Montserrat - ExtraBold</span>
                        </div>
                        <div class="title" v-if="launchTab == 3">
                            <span style="float:left;">关键词</span>
                            <span style="float:right;">方正兰亭 - 特黑 / Montserrat - ExtraBold</span>
                        </div>
                        <div class="title" v-if="launchTab == 5">
                            <span style="float:left;">必备单品</span>
                            <span style="float:right;">方正兰亭 - 特黑 / Montserrat - ExtraBold</span>
                        </div>
                        <div class="text">
                            <Input v-model="editObj.cn.coverTitle" type="textarea" :rows="2"></Input>
                        </div>
                    </div>
                </div>
            </div>
            <div class="launch-box launch-margin-top">
                <div class="launch-select-btn" style="margin-top: 0px">
                    <div class="btn">
                        <a href="javascript:" v-if="launchTab!=4" class="select" @click="onSelectProduct">Select / Edit Product List</a>
                        <a href="javascript:" v-if="launchTab==4" class="select" style="padding:0 60px" @click="brandVisible?brandVisible = false:brandVisible = true">Select Brand</a>
                    </div>
                    <div class="search-brand" v-if="launchTab==4 && brandVisible">
                        <div class="menu-search">
                            <div class="menu-input">
                                <input type="text" v-model="searchVal" @keyup="onSearch" class="input" placeholder="Search"/>
                            </div>
                            <a href="javascript:" class="icon"></a>
                        </div>
                        <div class="menu-body menu-brands-scroll">
                            <ul>
                                <li :key="'menu'+index" v-for="(brand,index) in editObj.en.brandList" >
                                    <div class="label">
                                        <i class="label-icon" @click="onSelectType(brand)" :class="brand.id == editObj.en.description?'select':'selected'"></i>
                                        <a href="javascript:" @click="onSelectType(brand)">{{ brand.name }}</a>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <!--e: cover-->


            <!--s: Btn-->
            <div class="launch-btn-edit">
                <div class="box">
                    <Button style="margin-left: 30px" :loading="loadingStatus" @click="onEditSubmit">Save</Button>
                </div>
            </div>
            <!--s: Btn-->

        </div>
        <!--e: Launch -->

    </div>
</template>
<script>
import SearchEdit from './edit'
export default SearchEdit
</script>
<style lang="less">
@import "edit";
</style>
